import { BASE } from "@/api/index";
import axios from "@/api/http";

const BASE_URL = BASE.PRO2;

/**
 * 集配中心-车辆排位-列表数据
 * @param {*} params
 */
export const postLogisticSortList = (params = {}) => {
  return axios.post(`${BASE_URL}/logistic/sort/list`, params);
};

/**
 * 集配中心-车辆排位-修改排位
 * @param {*} params
 */
export const postLogisticSortSave = (params = {}) => {
  return axios.post(`${BASE_URL}/logistic/sort/save`, params);
}

/**
 * 集配-车辆排位-城市仓下的自提点列
 * @param {*} params
 */
export const postDeliverySortList = (params = {}) => {
  return axios.post(`${BASE_URL}/delivery/sort/list`, params);
}

/**
 * 集配-车辆排位-城市仓下的自提点列-修改
 * @param {*} params
 */
export const postDeliverySortSave = (params = {}) => {
  return axios.post(`${BASE_URL}/delivery/sort/save`, params);
}

/**
 * 集配-货号搜索
 * @param {*} params
 */
export const getSupplyOrderBySku = (params = {}) => {
  return axios.post(`${BASE_URL}/entruck/getSupplyOrderBySku`, params);
}
