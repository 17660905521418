<template>
  <section class="page-wrap">
    <!-- 表单 start -->
    <div class="search">
      <div class="title">
        <div class="search-item">
          <el-input
            v-model="formData.zone_num"
            placeholder="输入主序号"
            :clearable="true"
            clearable
          ></el-input>
        </div>
        <div class="search-item">
          <el-select
            placeholder="选择城市仓"
            v-model="formData.logistic_id"
            @change="onChangeCityStore"
            clearable
            filterable
          >
            <el-option
              v-for="item in cityStoreList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <el-select
            placeholder="选择自提点"
            v-model="formData.delivery_id"
            :loading="selfTakeListLoading"
            filterable
            clearable
          >
            <el-option
              v-for="item in selfTakeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <el-button
            class="bjsearch"
            type="primary"
            icon="el-icon-refresh"
            @click="onHandleRefresh"
          ></el-button>
        </div>
        <div class="search-item">
          <el-button
            @click="onHandleSearch"
            type="primary"
            icon="el-icon-search"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <!-- 表单 end -->
    <!-- table start -->
    <div class="table-wrap">
      <el-table
        ref="refTable"
        height="100%"
        v-loading="loading"
        row-key="rowId"
        :data="dataList"
        :load="onHandleLoad"
        :tree-props="{
          children: 'children',
          hasChildren: 'hasChildren',
        }"
        @cell-click="onHandleCellClick"
        @expand-change="onHandleExpandChange"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        tooltip-effect="dark"
        lazy
        border
      >
        <el-table-column prop="name" label="城市仓" align="left">
          <template slot-scope="scope">{{ scope.row.name || "-" }}</template>
        </el-table-column>
        <el-table-column label="位置" prop="position" align="center">
          <template slot-scope="scope">
            <div class="d-flex a-center input-box">
              <el-input
                v-model="scope.row.position"
                placeholder="请输入位置，最多10个字,按回车确认"
                maxlength="10"
                @keyup.enter.native="
                  onHandleEnter(scope.$index, scope.row, 'position')
                "
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="序号" prop="order_time" align="center">
          <template slot-scope="scope">
            <div class="d-flex a-center input-box">
              <el-input
                v-model="scope.row.zone_num"
                placeholder="请输入主序号,按回车确认"
                @keyup.enter.native="
                  onHandleEnter(scope.$index, scope.row, 'zone_num')
                "
              ></el-input>
              <div>—</div>
              <el-input
                v-model="scope.row.place_num"
                placeholder="请输入副序号,按回车确认"
                @keyup.enter.native="
                  onHandleEnter(scope.$index, scope.row, 'place_num')
                "
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div class="d-flex" style="justify-content: center">
              <div
                @click="onHandleEnter(scope.$index, scope.row)"
                class="main-color pointer text-none mr40"
              >
                保存
              </div>
              <div
                @click="onReset(scope.$index, scope.row)"
                class="main-color pointer text-none"
              >
                清空
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- table end -->
  </section>
</template>

<script>
import debounce from "lodash/debounce";
import {
  postLogisticSortList,
  postLogisticSortSave,
  postDeliverySortList,
  postDeliverySortSave,
} from "@/api/logisticsControl/parking-space";
import cloneDeep from "lodash/cloneDeep";
// import tool from "@/utils/tools/tool";
import { Message } from "element-ui";

export default {
  name: "ParkingSpace",
  data() {
    return {
      formData: {
        logistic_id: "",
        delivery_id: "",
        zone_num: "",
        page: 1,
        pageSize: 100,
      },
      // 自提点列表
      selfTakeList: [],
      selfTakeListLoading: false,
      input: "",
      deepFromData: {},
      total: 0,
      dataList: [],
      cityStoreList: [],
      defaultList: [],
      loading: false,
    };
  },
  mounted() {
    this.deepFromData = cloneDeep(this.formData);
    this.onHandleData();
    this.getAjaxCityStoreList();
    // if (window.location.href.indexOf("?") > -1) {
    //   const url = this.tool.getUrl(window.location.href);
    //   url.page = url.page || 1;
    //   url.pageSize = url.pageSize || 100;
    //   this.formData = url;
    //   this.formData.page = +this.formData.page;
    //   this.formData.pageSize = +this.formData.pageSize;
    //   console.log(this.formData);
    // }
    this.getAjaxSelfTakeList({});
  },
  methods: {
    /**
     * 城市仓列表
     */
    async getAjaxCityStoreList() {
      const params = {
        name: "",
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.logistics.entruckLogisticslist(params);
        this.cityStoreList = data.data;
        console.log("ajx getAjaxCityStoreList", data.data);
      } catch (err) {
        console.log("ajx getAjaxCityStoreList err", err);
      }
    },
    /**
     * 获取自提点列表
     */
    getAjaxSelfTakeList: debounce(async function ({
      name = "",
      logisticsId = "",
    }) {
      this.selfTakeListLoading = true;
      /**
       * 城市仓为必传参数
       * 防止接口在删除城市仓前请求数据，故先判断条件
       */
      // if (!logisticsId || !this.formData.logistic_id) {
      //   return;
      // }
      const params = {
        name,
        logistics_id: (logisticsId && [logisticsId]) || [],
        page: 1,
        pageSize: 1000,
        from: "qc",
      };
      try {
        const { data } = await this.$api.logistics.entruckDeliverylist(params);
        this.selfTakeList = data.data;
        this.selfTakeListLoading = false;
        console.log("ajx getAjaxSelfTakeList", data.data);
      } catch (err) {
        console.log("ajx getAjaxSelfTakeList err", err);
      }
    }),
    /**
     * 城市仓发生变化
     */
    onChangeCityStore(data) {
      // 重置自提点
      this.selfTakeList = [];
      this.formData.delivery_id = "";
      const params = {
        name: "",
        logisticsId: data,
      };
      this.getAjaxSelfTakeList(params);
    },
    /**
     * 更新数据
     */
    async updateData(index, val, name) {
      try {
        let { data } = await postLogisticSortList(this.formData);
        this.dataList = data.data;
        this.total = data.total;
      } catch (err) {
        console.error("postLogisticSortSave err", err);
      }
    },
    /**
     * 校验数据
     */
    formatterHandle(val) {
      val["zone_num"] = +val["zone_num"] === 0 ? "" : val["zone_num"];
      val["place_num"] = +val["place_num"] === 0 ? "" : val["place_num"];
      if (
        (!/^[1-9]\d*$/.test(val["zone_num"]) && val["zone_num"]) ||
        (!/^[1-9]\d*$/.test(val["place_num"]) && val["place_num"])
      ) {
        Message({
          message: "序号是大于0的正整数",
          type: "error",
        });
        val["zone_num"] =
          !/^[1-9]\d*$/.test(val["zone_num"]) && val["zone_num"]
            ? ""
            : val["zone_num"];
        val["place_num"] =
          !/^[1-9]\d*$/.test(val["place_num"]) && val["place_num"]
            ? ""
            : val["place_num"];
        return false;
      } else {
        return true;
      }
    },
    /**
     * 修改排位数据
     */
    onHandleEnter(index, row, name) {
      // console.log("🆒 onHandleEnter", index, row, name);
      const valBoolean = this.formatterHandle(row);
      if (valBoolean) {
        this.loading = true;
        // 基础参数
        const params = {
          zone_num: row.zone_num,
          place_num: row.place_num,
          position: row.position,
        };
        if (row?.logistics_id) {
          Object.assign(params, {
            delivery_id: row.id,
            logistic_id: row.logistics_id,
          });
          this.ajaxPostDeliverySortSave(params);
        } else {
          Object.assign(params, {
            logistic_id: row.id,
          });
          this.ajaxPostLogisticSortSave(params);
        }
      }
    },
    /**
     * 修改了城市仓数据
     */
    async ajaxPostLogisticSortSave(params, row, isClear) {
      try {
        await postLogisticSortSave(params);
        if (isClear) {
          // 触发了清空
          row.zone_num = "";
          row.place_num = "";
          row.position = "";
          this.$message.success("已清空");
          // this.$set(this.dataList[index], "zone_num", "");
          // this.$set(this.dataList[index], "place_num", "");
          // this.$set(this.dataList[index], "position", "");
        } else {
          this.$message.success("修改成功");
        }
      } catch (err) {
        console.error("ajax postLogisticSortSave err", err);
      } finally {
        this.loading = false;
      }
    },
    /**
     * @explain 修改了自提点数据
     * @param {*} params 接口参数 -必传
     * @param {*} row 当前行数据 -非必传
     * @param {*} isClear 当前操作是否为清空 -非必传
     */
    async ajaxPostDeliverySortSave(params, row, isClear) {
      try {
        await postDeliverySortSave(params);
        if (isClear) {
          row.zone_num = "";
          row.place_num = "";
          row.position = "";
          this.$message.success("已清空");
          // const parentIndex = this.dataList.findIndex(
          //   (item) => item.id === params.logistic_id
          // );
          // console.log("🆒 ajaxPostDeliverySortSave", parentIndex);
          // document
          //   .querySelectorAll(".el-table__expand-icon")
          //   ?.[parentIndex]?.click();
        } else {
          this.$message.success("修改成功");
        }
      } catch (err) {
        console.error("ajax postDeliverySortSave err", err);
      } finally {
        this.loading = false;
      }
    },
    /**
     * 清空排位数据
     */
    onReset(index, row) {
      // console.log("🆒 onReset", index, row);
      this.$confirm("确认要清空吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        // 基础参数
        const params = {
          zone_num: "",
          place_num: "",
          position: "",
        };
        if (row?.logistics_id) {
          Object.assign(params, {
            delivery_id: row.id,
            logistic_id: row.logistics_id,
          });
          this.ajaxPostDeliverySortSave(params, row, true);
        } else {
          Object.assign(params, {
            logistic_id: row.id,
          });
          this.ajaxPostLogisticSortSave(params, row, true);
        }
      });
    },
    /**
     * 刷新数据
     */
    onHandleRefresh() {
      try {
        this.formData = cloneDeep(this.deepFromData);
        // this.tool.getResult(this.formData, window.location.href);
        this.onHandleData();
      } catch (err) {
        console.log("ajax err", err);
      }
    },
    /**
     * 获取数据
     */
    async onHandleData() {
      try {
        this.loading = true;
        const { data } = await postLogisticSortList(this.formData);
        // 遍历添加子项展开属性
        const resData = [...data.data];
        resData.forEach((item) => {
          // 将自提点数量大于0的，加懒加载
          item.hasChildren = (item?.delivery_number || 0) > 0 ? true : false;
          item.rowId = item.id + "";
        });
        // console.log("🆒 onHandleData", resData);
        this.dataList = cloneDeep(resData);
        this.defaultList = cloneDeep(resData);
        this.total = data.total;
      } catch (err) {
        console.log("ajax err", err);
      } finally {
        this.loading = false;
      }
    },
    /**
     * 查询
     */
    async onHandleSearch() {
      try {
        this.formData.page = 1;
        // this.tool.getResult(this.formData, window.location.href);
        await this.onHandleData();
        // 当搜索了自提点，则展开城市仓
        this.formData.delivery_id &&
          document.querySelectorAll(".el-table__expand-icon")?.[0]?.click();
      } catch (err) {
        console.log("ajax err", err);
      }
    },
    /**
     * 更新了每页数据
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      // this.tool.getResult(this.formData, window.location.href);
      this.onHandleData();
    },
    /**
     * 更新了页码
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      // this.tool.getResult(this.formData, window.location.href);
      this.onHandleData();
    },
    /**
     * 当前行，懒加载
     */
    async onHandleLoad(row, treeNode, resolve) {
      // console.log("🆒 onHandleLoad row", row, treeNode);
      const params = {
        logistic_id: row.id,
      };
      try {
        const { data } = await postDeliverySortList(params);
        // 生成一个唯一id
        const list = data.map((item) => {
          return {
            ...item,
            rowId: item.id + "" + item.logistics_id,
          };
        });
        resolve(list);
      } catch (err) {
        console.log("ajax postDeliverySortList err", err);
      }
    },
    /**
     * 点击单元格（城市仓）
     */
    onHandleCellClick(row, column, cell, event) {
      // 主动触发展开
      if (row.hasChildren && column.property === "name") {
        const node = this.$refs.refTable?.store?.states?.treeData?.[row.rowId];
        // 触发关闭时，重置缓存数据，重置状态
        if (node && !node.expanded) {
          node.children = [];
          node.loaded = false;
          node.expanded = false;
        }
        event.currentTarget?.querySelector(".el-table__expand-icon")?.click();
        // 当展开时，触发懒加载，更新数据
        // if (
        //   !event.currentTarget?.querySelector(
        //     ".el-table__expand-icon--expanded"
        //   )
        // ) {
        //   this.$refs.refTable?.load(row, column, cell, event);
        //   this.$refs.refTable?.load();
        // }
      }
      // console.log("🆒 onHandleCellClick", this.$refs.refTable);
      // console.log("🆒 row, column, cell, event", row, column, cell, event);
    },
    /**
     * 触发关闭时，重置缓存数据，重置状态
     */
    onHandleExpandChange(row, expanded) {
      const node = this.$refs.refTable?.store?.states?.treeData?.[row.rowId];
      if (node && !expanded) {
        node.children = [];
        node.loaded = false;
        node.expanded = false;
      }
      // console.log("🆒 onHandleExpandChange", row, expanded);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;

  .search {
    box-sizing: border-box;
    background: #fff;
    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
    .title {
      padding: 15px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
  }

  .table-wrap {
    flex-grow: 1;
    overflow-y: hidden;
    background: #fff;
    /deep/ .el-input__inner {
      border: 0;
      text-align: center;
    }
    .input-box {
      background: #ffffff;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
    }
  }

  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}

/** table样式 */
/deep/ .el-table {
  thead tr th {
    &:first-child {
      padding-left: 30px;
    }
  }
  tbody tr td {
    &:first-child {
      padding-left: 10px;
      font-weight: 600;
      cursor: pointer;
    }
  }
  .el-table__row {
    &.el-table__row--level-1 {
      td {
        &:first-child {
          font-weight: 450;
        }
      }
    }
  }
}
</style>
