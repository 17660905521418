var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "page-wrap" }, [
    _c("div", { staticClass: "search" }, [
      _c("div", { staticClass: "title" }, [
        _c(
          "div",
          { staticClass: "search-item" },
          [
            _c("el-input", {
              attrs: {
                placeholder: "输入主序号",
                clearable: true,
                clearable: "",
              },
              model: {
                value: _vm.formData.zone_num,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "zone_num", $$v)
                },
                expression: "formData.zone_num",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "search-item" },
          [
            _c(
              "el-select",
              {
                attrs: {
                  placeholder: "选择城市仓",
                  clearable: "",
                  filterable: "",
                },
                on: { change: _vm.onChangeCityStore },
                model: {
                  value: _vm.formData.logistic_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "logistic_id", $$v)
                  },
                  expression: "formData.logistic_id",
                },
              },
              _vm._l(_vm.cityStoreList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "search-item" },
          [
            _c(
              "el-select",
              {
                attrs: {
                  placeholder: "选择自提点",
                  loading: _vm.selfTakeListLoading,
                  filterable: "",
                  clearable: "",
                },
                model: {
                  value: _vm.formData.delivery_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "delivery_id", $$v)
                  },
                  expression: "formData.delivery_id",
                },
              },
              _vm._l(_vm.selfTakeList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "search-item" },
          [
            _c("el-button", {
              staticClass: "bjsearch",
              attrs: { type: "primary", icon: "el-icon-refresh" },
              on: { click: _vm.onHandleRefresh },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "search-item" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.onHandleSearch },
              },
              [_vm._v("查询")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "table-wrap" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "refTable",
            attrs: {
              height: "100%",
              "row-key": "rowId",
              data: _vm.dataList,
              load: _vm.onHandleLoad,
              "tree-props": {
                children: "children",
                hasChildren: "hasChildren",
              },
              "header-cell-style": { color: "#333333", background: "#EFF6FF" },
              "tooltip-effect": "dark",
              lazy: "",
              border: "",
            },
            on: {
              "cell-click": _vm.onHandleCellClick,
              "expand-change": _vm.onHandleExpandChange,
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "name", label: "城市仓", align: "left" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_vm._v(_vm._s(scope.row.name || "-"))]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "位置", prop: "position", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex a-center input-box" },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入位置，最多10个字,按回车确认",
                              maxlength: "10",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onHandleEnter(
                                  scope.$index,
                                  scope.row,
                                  "position"
                                )
                              },
                            },
                            model: {
                              value: scope.row.position,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "position", $$v)
                              },
                              expression: "scope.row.position",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "序号", prop: "order_time", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex a-center input-box" },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入主序号,按回车确认" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onHandleEnter(
                                  scope.$index,
                                  scope.row,
                                  "zone_num"
                                )
                              },
                            },
                            model: {
                              value: scope.row.zone_num,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "zone_num", $$v)
                              },
                              expression: "scope.row.zone_num",
                            },
                          }),
                          _c("div", [_vm._v("—")]),
                          _c("el-input", {
                            attrs: { placeholder: "请输入副序号,按回车确认" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onHandleEnter(
                                  scope.$index,
                                  scope.row,
                                  "place_num"
                                )
                              },
                            },
                            model: {
                              value: scope.row.place_num,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "place_num", $$v)
                              },
                              expression: "scope.row.place_num",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "操作", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex",
                          staticStyle: { "justify-content": "center" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "main-color pointer text-none mr40",
                              on: {
                                click: function ($event) {
                                  return _vm.onHandleEnter(
                                    scope.$index,
                                    scope.row
                                  )
                                },
                              },
                            },
                            [_vm._v(" 保存 ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "main-color pointer text-none",
                              on: {
                                click: function ($event) {
                                  return _vm.onReset(scope.$index, scope.row)
                                },
                              },
                            },
                            [_vm._v(" 清空 ")]
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "fy" },
      [
        _c("el-pagination", {
          attrs: {
            type: "primary",
            background: "",
            "current-page": _vm.formData.page,
            "page-sizes": [10, 20, 50, 100],
            "page-size": _vm.formData.pageSize,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "size-change": _vm.onHandleSizeChange,
            "current-change": _vm.onHandleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }